<template>
  <transition appear>
    <div
      class="bg-white w-11.5/12 rounded-xl my-5 mx-auto py-1.5 px-5 shadow-lg"
    >
      <div class="flex justify-between items-center">
        <div class="mt-5 flex justify-start items-center cursor-pointer">
          <span @click="goBack" class="material-icons rounded-full bg-teal text-white">
            chevron_left
          </span>
          <router-link
            class="font-bold text-teal mx-2"
            :to="{ name: `re-locums` }"
          >
            Back To Dashboard
          </router-link>
        </div>
      </div>

      <div class="bg-lightGrey rounded-xl w-12/12 my-5 mx-auto py-7 px-5">
        <h2 class="text-grey font-bold text-2xl">
          Showing all completed sessions
        </h2>
        <div class="flex flex-wrap">
          <div class="mt-5 flex gap-5 mr-5">
            <div>
              <select
                v-model="filtersInput.location"
                class="bg-white w-80 py-1.5 px-2 text-grey"
              >
                <option :value="null" disabled selected>
                  Select Locations
                </option>
                <option
                  v-for="location in locations"
                  :key="location.id"
                  :value="location.id"
                >
                  {{
                    location.practice_name[0].toUpperCase() +
                    location.practice_name.slice(1)
                  }}
                </option>
              </select>
            </div>
            <div>
              <va-date-input
                v-model="filtersInput.start_date"
                class="bg-white w-80 text-grey"
                color="gray"
                placeholder="Select Start Date"
              />
            </div>
            <div>
              <va-time-input
                v-model="filtersInput.start_time"
                :format="formatFn"
                class="bg-white w-80 text-grey"
                color="gray"
                placeholder="Select Start Time"
              />
            </div>
            <div>
              <select
                v-model="filtersInput.invoice_status"
                class="bg-white w-80 py-1.5 px-2 text-grey"
              >
                <option :value="null" disabled selected>Invoice Status</option>
                <option value="1">Pending</option>
                <option value="2">Rejected</option>
                <option value="3">Approved</option>
                <option value="4">Paid</option>
              </select>
            </div>
          </div>
          <div class="mt-5 flex gap-5 mr-5">
            <div>
              <input
                type="text"
                placeholder="Select Rate"
                v-model="filtersInput.rate"
                class="bg-white w-80 py-1.5 px-2 text-grey"
              />
            </div>
            <div>
              <va-date-input
                v-model="filtersInput.end_date"
                class="bg-white w-80 text-grey"
                color="gray"
                placeholder="Select Finish Date"
              />
            </div>
            <div>
              <va-time-input
                v-model="filtersInput.end_time"
                :format="formatFn"
                class="bg-white w-80 text-grey"
                color="gray"
                placeholder="Select Finish Time"
              />
            </div>
            <div>
              <select
                v-model="filtersInput.esm_status"
                class="bg-white w-80 py-1.5 px-2 text-grey"
              >
                <option :value="null" disabled selected>
                  Select ESM Status
                </option>
                <option value="1">Pending</option>
                <option value="2">Rejected</option>
                <option value="3">Approved</option>
                <option value="4">Paid</option>
              </select>
            </div>
          </div>
          <div class="flex justify-center mt-4 items-center">
            <va-button
              color="#00b5b5"
              text-color="white"
              @click="filterResults"
            >
              Apply Filters
            </va-button>
            <va-button
              outline
              color="#00b5b5"
              class="ml-3"
              @click="clearFilters"
            >
              Clear All
            </va-button>
          </div>
        </div>
        <div class="bg-lightGrey rounded-xl min-h-cardM mt-5">
          <table class="text-center w-full bg-white text-teal rounded-xl my-2">
            <dynamic-table :showTitle="false" title="">
              <locum-billing-table-item
                :data="billingSessionsRows"
                :link="'hq-staff-request-form'"
                :myParameter="'staffRequestForm'"
              >
              </locum-billing-table-item>
            </dynamic-table>
          </table>
          <my-pagination
            class="mt-5"
            :options="options"
            v-model="declinedPage"
            :records="30"
            :per-page="10"
            @paginate="myCallback($event, 'declined')"
          >
          </my-pagination>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import dynamicTable from "@/components/ui/baseComponents/Tables/greyTable.vue";
import locumBillingTableItem from "@/components/ui/baseComponents/Tables/RE/locumBillingTableItem.vue";
import { markRaw } from "vue";
import customPaginate from "@/components/ui/baseComponents/paginate.vue";
import moment from "moment";

export default {
  name: "locumBilling",
  provide() {
    return { headers: this.tableHeaders };
  },
  async created() {
    await this.fetchBillingSessions();
    this.getBillingSessions();
    await this.fetchLocations();
    this.getLocations();
  },
  components: {
    dynamicTable,
    locumBillingTableItem,
  },
  data() {
    return {
      options: {
        template: markRaw(customPaginate),
      },
      formatFn: (d) => d?.toLocaleTimeString?.(),
      filtersInput: {
        location: null,
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        invoice_status: null,
        rate: null,
        esm_status: null,
      },
      billingSessions: [],
      escalatedIsLoading: false,
      declinedIsLoading: false,
      image: null,
      nullPage: 1,
      approvedPage: 1,
      escalatedPage: 1,
      declinedPage: 1,
      tableHeaders: [
        "Location",
        "Start Day",
        "End Day",
        "Start Time",
        "Finish Time",
        "Rate",
        "Invoice For Session",
        "ESM Status",
      ],
      dropdownOptions: [
        {
          label: "UK",
          value: "uk",
          type: "input",
        },
        {
          label: "UK",
          value: "uk",
          type: "input",
        },
      ],
      billingSessionsRows: [],
      filtersPayload: {},
      locations: [],
    };
  },
  methods: {
    async filterResults() {
      this.filtersPayload.location = this.filtersInput.location;
      this.filtersPayload.invoice_status = this.filtersInput.invoice_status;
      this.filtersPayload.rate = this.filtersInput.rate;
      this.filtersPayload.esm_status = this.filtersInput.esm_status;

      this.filtersPayload.start_date = moment(
        this.filtersInput.start_date
      ).format("YYYY-MM-DD");
      this.filtersPayload.end_date = moment(this.filtersInput.end_date).format(
        "YYYY-MM-DD"
      );
      this.filtersPayload.start_time = moment(
        this.filtersInput.start_time
      ).format("HH:mm:ss");
      this.filtersPayload.end_time = moment(this.filtersInput.end_time).format(
        "HH:mm:ss"
      );

      for (const key of Object.keys(this.filtersPayload)) {
        if (
          this.filtersPayload[key] === null ||
          this.filtersPayload[key] === "Invalid date"
        ) {
          delete this.filtersPayload[key];
        }
      }
      console.log("Filters: ", this.filtersPayload);

      await this.fetchBillingSessions(this.filtersPayload);
      this.getBillingSessions();
    },
    async clearFilters() {
      this.filtersInput = {
        location: null,
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        invoice_status: null,
        rate: null,
        esm_status: null,
      };
      await this.fetchBillingSessions();
      this.getBillingSessions();
    },
    async fetchBillingSessions(payload = { page: 1 }) {
      try {
        await this.$store.dispatch("ReLocums/fetchBillingSessions", payload);
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    getBillingSessions() {
      this.billingSessions = this.$store.getters["ReLocums/getBillingSessions"];
      const esm_statuses = {
        1: "Pending",
        2: "Rejected",
        3: "Approved",
        4: "Paid",
      };
      this.billingSessionsRows = this.billingSessions.data.map((session) => {
        return {
          id: session.id,
          location:
            session.location.practice_name[0].toUpperCase() +
            session.location.practice_name.slice(1),
          start_date: session.start_date,
          start_time: session.start_time,
          end_date: session.end_date,
          end_time: session.end_time,
          rate: "£ " + session.rate,
          esm_status: esm_statuses[session.esm_status],
          invoice: session.session_invoice ? `<span class="material-icons-outlined text-teal text-4xl cursor-pointer">
                      receipt_long
                    </span>` : ``,
        };
      });
      console.log("Billing ROWS:", this.billingSessionsRows);
      //console.log('Billing Sessions From Component: ',this.billingSessions)
      return this.$store.getters["ReLocums/getBillingSessions"];
    },
    async fetchLocations() {
      try {
        await this.$store.dispatch("ReLocums/fetchLocations");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    getLocations() {
      this.locations = this.$store.getters["ReLocums/getLocations"];
      console.log("LOCATIONS From STAFF PAGE: ", this.locations);
      return this.locations;
    },
    goBack() {
      this.$router.push({ name: "re-locums" });
    },
  },
};
</script>

<style scoped>
.filterDropDown .vs__dropdown-toggle,
.filterDropDown .vs__dropdown-menu {
  border-radius: 0.5rem;
  background: lightgray;
  padding-left: 0.2rem;
}
.filterDropDown .vs__dropdown-toggle {
  height: 2.5rem;
  width: 100%;
}
#filterDropDown .filterDropDown .vs__clear,
#filterDropDown .filterDropDown .vs__open-indicator {
  fill: black;
}
.filterDropDown {
  min-width: 300px;
  border: none;
  background: lightgray;
}
.session-row {
  min-width: 400px;
  max-width: 400px;
}
</style>
